<template>
  <div class="">
    
    <v-card :loading="loading" :disabled="loading" class="shadow-off" loader-height="2">
      <v-card-text>
        <div class="d-flex align-center mb-4">
          <v-btn
            small
            color="btnColor"
            class="mr-1 white--text"
            @click="
              addeditdialog = true;
              addeditdata = {};
              addeditdataerror = {};
              locationUserList = [];
              customassertions = false;
            "
            v-if="$nova.hasRight(right, 'add')"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
            <span>New</span>
          </v-btn>
          <bulkChecklistUpload
            @reload="refreshData()"
            v-if="$nova.hasRight(right, 'add')"
          />
          <v-btn class="shadow-off" text @click="refreshData()" small icon
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
          <v-tooltip bottom content-class="tooltip-bottom">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="shadow-off" v-bind="attrs" v-on="on" text @click="exportToCsv()" small icon
              ><v-icon>mdi-file-excel-outline</v-icon></v-btn
            >
            </template>
            <span>Download Excel</span>
          </v-tooltip>
        </div>
        <DataTable
          :headers="headers"
          :items="formatedData"
          :enableslot="[
            'action',
            'assertionscount',
            'preparer',
            'approver',
            'tags',
            'location',
          ]"
          :loading="loading"
          :multiselectkey="($nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')) ? '_id':'' "
          :bgColor="true"
        >
          <template v-slot:additionalinfo="{ value }">
            <div v-if="value.length > 0">
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="value.length > 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')"
                    icon
                    small
                    class=""
                    @click="bulkActionUserlocationdilog('location', value)"
                    ><v-icon>mdi-map-marker-plus-outline</v-icon></v-btn
                  >
                </template>
                <span>Bulk Add/Change Location</span>
              </v-tooltip>
              <!-- <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="value.length > 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="info"
                    icon
                    small
                    class=""
                    @click="bulkActionUserdilog('preparer', value)"
                    ><v-icon>mdi-account</v-icon></v-btn
                  >
                </template>
                <span>Bulk Add/Remove Preparer</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="value.length > 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="warning"
                    icon
                    small
                    class=""
                    @click="bulkActionUserdilog('approver', value)"
                    ><v-icon>mdi-account</v-icon></v-btn
                  >
                </template>
                <span>Bulk Add/Remove Approver</span>
              </v-tooltip> -->
            </div>
          </template>
          <template v-slot:assertionscount="{ item }">
            <div class="text-center">
              <v-avatar size="30" class="mr-1" color="grey lighten-3">
                {{ item.assertionscount }}
              </v-avatar>
            </div>
          </template>
          <template v-slot:preparer="{ item }">
            <div
              class="d-flex justify-center"
              v-if="(item.preparer || []).length > 0"
            >
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-for="(vv, kk) in item.preparer.slice(0, 2)"
                :key="kk"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div>
                    <v-avatar
                      size="30"
                      class="mr-1"
                      :style="`background-color:${
                        (vv || {}).color || 'rgba(0,0,0,0.2)'
                      }`"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        (((vv || {}).name || "U N").split(" ") || [])
                          .slice(0, 2)
                          .map((word) => word[0].toUpperCase())
                          .join("")
                      }}
                    </v-avatar>
                  </div>
                </template>
                <span>{{ (vv || {}).name || "Unknown User" }}</span>
              </v-tooltip>
              <v-avatar
                size="30"
                v-if="(item.preparer || []).length > 2"
                class="mr-1 border-on"
              >
                +{{ (item.preparer || []).length - 2 }}
              </v-avatar>
            </div>
          </template>
          <template v-slot:approver="{ item }">
            <div
              class="d-flex justify-center"
              v-if="(item.approver || []).length > 0"
            >
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-for="(vv, kk) in item.approver.slice(0, 2)"
                :key="kk"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    size="30"
                    class="mr-1"
                    :style="`background-color:${
                      (vv || {}).color || 'rgba(0,0,0,0.2)'
                    }`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{
                      (((vv || {}).name || "U N").split(" ") || [])
                        .slice(0, 2)
                        .map((word) => word[0].toUpperCase())
                        .join("")
                    }}
                  </v-avatar>
                </template>
                <span>{{ (vv || {}).name || "Unknown User" }}</span>
              </v-tooltip>
              <v-avatar
                size="30"
                v-if="(item.approver || []).length > 2"
                class="mr-1 border-on"
              >
                +{{ (item.approver || []).length - 2 }}
              </v-avatar>
            </div>
          </template>
          <template v-slot:tags="{ item }">
            <v-chip
              v-for="(tv, tk) in item.tags"
              :key="tk"
              x-small
              label
              class="mr-1 mb-1"
              >{{ tv }}</v-chip
            >
          </template>

          <template v-slot:location="{ item }">
            <v-menu open-on-hover left offset-x :max-width="400" elevation-0>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-center">
                  <v-avatar
                    size="30"
                    class="mr-1"
                    color="grey lighten-3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ (item.locationlist || []).length }}
                  </v-avatar>
                </div>
              </template>
              <v-card>
                <v-card-text class="py-2 px-4">
                  <div v-for="(tv, tk) in item.locationlist" :key="tk">
                    {{ tk + 1 }}. {{ getLocationName(tv.location_id || "") }}

                    <div v-for="(p, i) in tv.preparer" :key="'A' + i">
                      <span><span class="font-weight-bold">Prepares(s):</span>{{ getPreparesName(p) }}</span>
                    </div>

                    <div v-for="(a, i) in tv.approver" :key="'B' + i">
                      <span
                        ><span class="font-weight-bold">Approver(s):</span
                        >{{ getApproversName(a) }}</span
                      >
                    </div>
                    <div></div>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </template>

          <template v-slot:action="{ item }">
            <div class="d-flex align-center justify-center">
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="$nova.hasRight(right, 'edit')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="addEditForm(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-if="item.status && $nova.hasRight(right, 'enabledisable')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="enableDisableAccess(item._id, 'disable')"
                  >
                     <v-icon>mdi-check-circle</v-icon>
                    
                  </v-btn>
                </template>
                <span>Enable</span>
              </v-tooltip>
              <v-tooltip
                bottom
                content-class="tooltip-bottom"
                v-else-if="
                  !item.status && $nova.hasRight(right, 'enabledisable')
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="enableDisableAccess(item._id, 'enable')"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Disable</span>
              </v-tooltip>
              <lb-activitylogwithicon
                :config="activitylogconfig"
                :url="`/v2/financialclose/masters/checklist/getlogs/${item._id}`"
              />
            </div>
          </template>
        </DataTable>
      </v-card-text>
    </v-card>

    <lb-dailogboxnew
      v-model="addeditdialog"
      :heading="addeditdata._id ? 'Update' : 'Add New'"
      width="600"
      :loading="addeditloading"
    >
      <template v-slot:body>
        <div>
          <v-row class="mt-0">
            <v-col cols="12" class="my-0 py-0">
              <lb-string
                v-model="addeditdata.description"
                label="Finance Close Task Description*"
                :error="addeditdataerror.description"
              />
            </v-col>
            <v-col cols="11" class="my-0 py-0">
              <lb-dropdown
                v-model="addeditdata.category"
                label="Balance sheet/Income statement primary classification*"
                :error="addeditdataerror.category"
                :items="categoryAcc"
                :customvalue="true"
              />
            </v-col>
            <v-col cols="1" class="py-0 mt-n1 ml-n4">
                <v-menu
                open-on-hover
                transition="scale-transition"
                elevation-0
                max-width="300"
                offset-y
                >
                 <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2" text icon color="lighten-2"  v-bind="attrs"
                    v-on="on">
                  <v-icon>mdi-information</v-icon>
                </v-btn>
                 </template>
                 <v-card>
                  <v-card-text class="text-6 font-weight-bold">
                    "Non-current assets, Current liabilities, Revenue from operations etc…"
                  </v-card-text>
                </v-card>
              </v-menu>
              </v-col>
            <v-col cols="11" class="my-0 py-0">
              <lb-dropdown
                v-model="addeditdata.subcategory"
                label="Balance sheet/Income statement secondary classification*"
                :error="addeditdataerror.subcategory"
                :items="subcategoryAcc"
                 :customvalue="true"
              />
            </v-col>
            <v-col cols="1" class="py-0 mt-n1 ml-n4">
                <v-menu
                open-on-hover
                transition="scale-transition"
                elevation-0
                max-width="300"
                offset-y
                >
                 <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2" text icon color="lighten-2"  v-bind="attrs"
                    v-on="on">
                  <v-icon>mdi-information</v-icon>
                </v-btn>
                 </template>
                 <v-card>
                  <v-card-text class="text-6 font-weight-bold">
                    "Property, plan and equipment, Trade payables, Revenue from subscription etc..."
                  </v-card-text>
                </v-card>
              </v-menu>
              </v-col>
            <div class="d-flex align-center pb-2 ml-4">
              <span class="text-capitalize font-weight-bold">Add {{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location' }}*:</span>
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="
                      adduserdialog = true;
                      addApprover = '';
                      addPrepares = '';
                      addLocation = '';
                    "
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    ><v-icon>mdi-plus-circle</v-icon></v-btn
                  >
                </template>
                <span>Add location*</span>
              </v-tooltip>
            </div>

            <v-col cols="12" class="my-0 py-0">
              <v-tabs v-model="tab" color="fbcPrimary" height="34">
                <v-tabs-slider></v-tabs-slider>
                <v-tab v-for="(item, i) in locationUserList" :key="i">
                  {{ getLocationName(item.location_id || "") }}
                   <v-icon right @click="deleteLocation(item.location_id)" color="error">
                    mdi-delete-circle 
                  </v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item
                  v-for="(item, i) in locationUserList"
                  :key="'A' + i"
                >
                  <v-card flat>
                    <v-card-text>
                      <div class="mb-1">
                        <div class="">
                          <span class="font-weight-bold">Prepares(s):</span>
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" @click="usersAddlocation(item.location_id,'Preparer',item.preparer,item.approver)" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                            </template>
                            <span>Add Preparer</span>
                          </v-tooltip>
                        </div>
                        <div> 
                          <v-chip
                            class="mr-2"
                            small
                            v-for="(v, k) in item.preparer"
                            :key="k"
                            close
                            @click:close="removeUser(item.location_id,v,'preparer')"
                          >
                            {{ getPreparesName(v) }}
                            
                          </v-chip>
                        </div>
                      </div>
                      <div class="mb-1">
                        <div class="">
                          <span class="font-weight-bold">Approver(s):</span>
                          <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn  v-bind="attrs" v-on="on"  @click="usersAddlocation(item.location_id,'Approver',item.preparer,item.approver)" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                          </template>
                          <span>Add Approver</span>
                        </v-tooltip>
                        </div>
                        <div>
                          <v-chip
                            class="mr-2"
                            small
                            v-for="(v, k) in item.approver"
                            :key="k"
                            close
                            @click:close="removeUser(item.location_id,v,'approver')"
                            
                          >
                            {{ getApproversName(v) }}
                          </v-chip>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>

            <v-col cols="12" class="my-0 py-0">
              <lb-list
                type="stringarray"
                v-model="addeditdata.tags"
                label="Tags*"
                :error="addeditdataerror.tags"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-0">
              <lb-textarea
                v-model="addeditdata.purpose"
                label="Purpose"
                :error="addeditdataerror.purpose"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-0">
              <lb-textarea
                v-model="addeditdata.instruction"
                label="Instruction"
                :error="addeditdataerror.instruction"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <lb-dropdown
                v-model="addeditdata.assertions"
                :items="possibleassertions"
                multiple
                label="Assertions"
                :error="addeditdataerror.assertions"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-0" v-show="customassertions">
                  <lb-string
                    label="Custom Assertion"
                    v-model="addeditdata.customassertions"
                    :error="addeditdataerror.customassertions"
                  />
                </v-col>
            <v-col cols="12" class="my-0 py-0">
              <lb-dropdown
                v-model="addeditdata.glcode"
                :items="possibleGlCodes"
                multiple
                label="Gl Code"
                :error="addeditdataerror.glcode"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-0">
              <lb-number v-model="addeditdata.duedays" label="Due days" placeholder="Enter due days" min="0"  :error="addeditdataerror.duedays"/>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <span class="caption">*Required</span>
        <v-spacer></v-spacer>
        <v-btn small color="btnColor" class="white--text" @click="addEditItem()">
          <span v-if="addeditdata._id">Update</span>
          <span v-else>Add</span>
        </v-btn>
      </template>
    </lb-dailogboxnew>

    <lb-dailogboxnew
      v-model="bulkactionuserdialog"
      :heading="'Manage ' + bulkactionuserusertype"
      width="500"
      :loading="bulkactionuserloading"
    >
      <template v-slot:body>
        <div class="switch-custom-color">
          <div class="d-flex align-center mb-1">
            <div class="display-1 mr-2">{{ multiselectdata.length || 0 }}</div>
            <div class="line-1-2">Item(s)<br />Selected</div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center mb-2">
              <!-- <span :class="`mr-3 ${bulkactionuserselected ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Add</span>
                <v-switch inset dense class="mt-0" color="default" v-model="bulkactionuserselected" label="" hide-details @change="bulkActionUser(false,multiselectdata)">
                </v-switch>
                <span :class="`ml-1 ${!bulkactionuserselected ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Remove</span> -->
              <lb-dropdown
                hidedetails
                label="Select Action*"
                v-model="selectaction"
                :items="
                  bulkactionuserusertype === 'Approver'
                    ? itemapprover
                    : itempreparer
                "
                itemtext="name"
                itemvalue="value"
                class="mt-2"
                width="10"
                @change="
                  actionselection(
                    selectaction,
                    bulkactionuserusertype,
                    multiselectdata
                  )
                "
              />
            </div>
          </div>
          <div v-if="bulkactionuserselected">
            Below selected user will be removed from all the selected items.
          </div>
          <div v-else>
            Below selected user will be added to all the selected items.
          </div>
          <lb-dropdown
            hidedetails
            label="Select User*"
            itemtext="displayname"
            itemvalue="_id"
            class="mt-2"
            v-model="bulkactionuserid"
            :items="possibleusers"
          />
          <lb-dropdown
            hidedetails
            :label="'Select ' + bulkactionuserusertype"
            v-model="changetouser"
            :items="formdata.users"
            itemtext="displayname"
            itemvalue="_id"
            class="mt-2"
            v-if="selectaction === 'replace'"
            :disabled="bulkactionuserid ? false : true"
          />
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          small
          v-if="selectaction === 'remove'"
          @click="
            bulkManageUser(
              selectaction,
              bulkactionuserusertype,
              bulkactionuserid,
              multiselectdata
            )
          "
          >Remove</v-btn
        >
        <v-btn
          color="btnColor"
          class="white--text"
          small
          v-else-if="selectaction === 'associate'"
          @click="
            bulkManageUser(
              selectaction,
              bulkactionuserusertype,
              bulkactionuserid,
              multiselectdata
            )
          "
          >Add</v-btn
        >
        <v-btn
          color="btnColor"
          class="white--text"
          small
          v-else-if="selectaction === 'replace'"
          @click="
            bulkManageUser(
              selectaction,
              bulkactionuserusertype,
              bulkactionuserid,
              multiselectdata,
              changetouser
            )
          "
          >Replace</v-btn
        >
        <v-btn
          color="btnColor"
          class="white--text"
          v-else-if="selectaction === 'move'"
          @click="
            bulkManageUser(
              selectaction,
              bulkactionuserusertype,
              bulkactionuserid,
              multiselectdata
            )
          "
          >Change</v-btn
        >
      </template>
    </lb-dailogboxnew>
    <lb-dailogboxnew
      v-model="viewchecklistdialog"
      heading="Manage Tasklist Details"
      width="800"
    >
      <template v-slot:body>
        <div>
          <div class="d-flex align-center">
            <span>{{ viewchecklistdata.category }}</span>
            <span>
              <v-icon>mdi-chevron-right</v-icon>
            </span>
            <span>{{ viewchecklistdata.subcategory }}</span>
          </div>
          <div class="d-flex body-1 mb-2">
            <span class="font-weight-bold">{{ viewchecklistdata.ref }}</span>
            <span class="">
              <v-icon>mdi-circle-small</v-icon>
            </span>
            <div class="">{{ viewchecklistdata.description || "" }}</div>
          </div>
          <div class="mb-2">
            <div class="d-flex align-center">
              <v-chip
                label
                small
                class="mr-2"
                color="info"
                v-if="viewchecklistdata.preparer || []"
                >Preparer</v-chip
              >
              <v-chip
                label
                small
                class="mr-2"
                color="warning"
                v-else-if="viewchecklistdata.approver || []"
                >Approver</v-chip
              >
              <span class="ml-1">Duedays:</span>
              <span class="ml-2">{{ viewchecklistdata.duedays }}</span>
            </div>
          </div>
          <div class="">
            <v-row>
              <v-col cols="12" class="mt-2">
                <div class="border-on shadow-on radius-on">
                  <div class="font-weight-bold px-4 py-1">
                    <div class="d-flex align-center">
                      <div class="body-2 pt-2">Assertions</div>
                      <v-spacer></v-spacer>
                    </div>
                  </div>
                  <div
                    class="mx-2 mb-2"
                    v-if="(viewchecklistdata.assertions || []).length > 0"
                  >
                    <v-chip
                      v-for="(va, ka) in viewchecklistdata.assertions"
                      :key="ka"
                      small
                      outlined
                      class="pl-2 mt-2 mr-1"
                    >
                      <v-icon>mdi-check</v-icon>
                      <span class="pl-1">{{ va }}</span>
                    </v-chip>
                  </div>
                  <div v-else class="grey--text px-4 mb-2">No Data</div>
                </div>
              </v-col>
              <v-col cols="12" class="mt-2">
                <div class="border-on shadow-on radius-on">
                  <div class="px-4 pb-2">
                    <v-row>
                      <v-col cols="12">
                        <div class="ml-1">
                          <div class="body-2 pt-3 pb-1">{{$store.state.LocationHeader ? $store.state.LocationHeader :'Location'}}</div>
                          <v-tabs v-model="tab" color="fbcPrimary" height="34">
                            <v-tabs-slider></v-tabs-slider>
                            <v-tab
                              fixed-tabs
                              v-for="(item, i) in viewchecklistdata.locationlist"
                              :key="i"
                            >
                              {{ getLocationName(item.location_id || "") }}
                            </v-tab>
                          </v-tabs>

                          <v-tabs-items v-model="tab">
                            <v-tab-item
                              v-for="(item, i) in viewchecklistdata.locationlist"
                              :key="'A' + i"
                            >
                              <v-card flat>
                                <v-card-text>
                                  <div class="mb-1">
                                    <div class="">
                                      <span class="font-weight-bold"
                                        >Prepares(s):</span
                                      >
                                    </div>
                                    <div>
                                      <v-chip
                                        class="mr-1 mb-1 ml-1"
                                        small
                                        v-for="(v, k) in item.preparer"
                                        :key="k"
                                      >
                                        {{ getPreparesName(v) }}
                                      </v-chip>
                                    </div>
                                  </div>
                                  <div class="mb-1">
                                    <div class="">
                                      <span class="font-weight-bold"
                                        >Approver(s):</span
                                      >
                                    </div>
                                    <div>
                                      <v-chip
                                        class="mr-1 mb-1 ml-1"
                                        small
                                        v-for="(v, k) in item.approver"
                                        :key="k"
                                      >
                                        {{ getApproversName(v) }}
                                      </v-chip>
                                    </div>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs-items>

                          <!-- <div v-else class="grey--text">No Data</div> -->
                        </div>
                      </v-col>

                      <v-col cols="6">
                        <div class="mr-1">
                          <div class="body-2 pt-3 pb-1">Purpose</div>
                          <div v-if="viewchecklistdata.purpose">
                            {{ viewchecklistdata.purpose }}
                          </div>
                          <div v-else class="grey--text">No Data</div>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="ml-1">
                          <div class="body-2 pt-3 pb-1">Instruction</div>
                          <div v-if="viewchecklistdata.instruction">
                            {{ viewchecklistdata.instruction }}
                          </div>
                          <div v-else class="grey--text">No Data</div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="border-on shadow-on radius-on px-4 py-1">
                  <p class="body-2 pt-2">Linked GL(s)</p>
                  
                  <div
                  class="mx-2 mb-2"
                  v-if="(viewchecklistdata.tags || []).length > 0"
                >
                <template  v-for="(va, ka) in viewchecklistdata.glcode">
                    
                    <v-chip
                      v-if="va"
                      :key="ka"
                      small
                      outlined
                      class="pl-2  mr-1"
                    >
                      <v-icon>mdi-check</v-icon>
                      <span class="pl-1">{{ va }}</span>
                    </v-chip>
                </template>
                </div>
                <div v-else class="grey--text px-4 mb-2">No Data</div>

                </div>
              </v-col>
              <v-col cols="12" class="mt-2">
                <div class="border-on shadow-on radius-on">
                  <div class="font-weight-bold px-4 py-1">
                    <div class="d-flex align-center">
                      <div class="body-2 pt-2">Tags</div>
                      <v-spacer></v-spacer>
                    </div>
                  </div>
                  <div
                    class="mx-2 mb-2"
                    v-if="(viewchecklistdata.tags || []).length > 0"
                  >
                    <v-chip
                      v-for="(va, ka) in viewchecklistdata.tags"
                      :key="ka"
                      small
                      outlined
                      class="pl-2 mt-2 mr-1"
                    >
                      <v-icon>mdi-check</v-icon>
                      <span class="pl-1">{{ va }}</span>
                    </v-chip>
                  </div>
                  <div v-else class="grey--text px-4 mb-2">No Data</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </lb-dailogboxnew>

    <lb-dailogboxnew
      v-model="locationdialog"
      :heading="'Location Based Users Bulk Change'"
      width="500"
      :loading="locationdialogloading"
    >
      <template v-slot:body>
        <div class="d-flex align-center">
          <v-card-text class="mt-2 pt-2">
            <v-row>
              <v-col cols="9" class="my-0 mb-1 py-0">
                <lb-dropdown
                  :items="location_details"
                  itemtext="name"
                  itemvalue="_id"
                  multiple
                  label="Location*"
                  v-model="addLocation"
                  :error="addLocationerr.locations"
                />
              </v-col>
              <v-col cols="3" class="my-0 py-0">
                <v-btn
                  color="btnColor"
          class="white--text mb-3"
                  @click="
                    addnewLocation = true;
                    locationdata = {};
                    locationdataerror = {};
                  "
                  elevation="0"
                  >Add Location</v-btn
                >
              </v-col>

              <v-col cols="12" class="my-0 mb-1 py-0">
                <lb-dropdown
                  v-model="addApprover"
                  :items="formdata.users"
                  itemtext="name"
                  itemvalue="_id"
                  multiple
                  label="Approver(s)*"
                  :error="addLocationerr.approvers"
                />
              </v-col>

              <v-col cols="12" class="my-0 py-0">
                <lb-dropdown
                  v-model="addPrepares"
                  :items="formdata.users"
                  itemtext="name"
                  itemvalue="_id"
                  multiple
                  label="Preparers(s)*"
                  :error="addLocationerr.preparers"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn
          color="btnColor"
          class="white--text"
          small
          @click="
            bulklocationChange(
              addLocation,
              addApprover,
              addPrepares,
              multiselectdata
            )
          "
          >submit</v-btn
        >
      </template>
    </lb-dailogboxnew>

    <v-dialog v-model="adduserdialog" max-width="400" persistent>
      <v-card class="">
        <v-card-text class=" d-flex align-center py-2 px-4">
          <span class="subtitle-1 ">Select User</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="adduserdialog = false" class="ml-2 "
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <lb-dropdown
                v-model="addLocation"
                :items="location_details"
                multiple
                :label="$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'"
                itemtext="name"
                itemvalue="_id"
              />
            </v-col>

            <v-col cols="12" class="my-0 py-0">
              <lb-dropdown
                hidedetails
                v-model="addApprover"
                :items="formdata.users"
                itemtext="name"
                itemvalue="_id"
                multiple
                label="Approver(s)"
              />
            </v-col>

            <v-col cols="12" class="my-0 py-0">
              <lb-dropdown
                hidedetails
                v-model="addPrepares"
                :items="formdata.users"
                itemtext="name"
                itemvalue="_id"
                multiple
                label="Preparer(s)"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn small color="btnColor" class="white--text" @click="addUserLocation(addLocation,addPrepares,addApprover)"
            >Add {{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}}</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <lb-dailogboxnew
      v-model="addnewLocation"
      :heading="'Add New Location'"
      width="600"
      :loading="addnewLocationloading"
    >
      <template v-slot:body>
        <div>
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <lb-string
                v-model="locationdata.name"
                label="Location Name*"
                :error="locationdataerror.name"
              />
            </v-col>

            <v-col cols="12" class="my-0 py-0">
              <lb-string
                v-model="locationdata.description"
                label="Location Description*"
                :error="locationdataerror.description"
              />
            </v-col>

            <v-col cols="12" class="my-0 py-0">
              <lb-string
                v-model="locationdata.country"
                label="Location Country*"
                :error="locationdataerror.country"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <lb-dropdown
                v-model="locationdata.type"
                :items="locationtypeItem"
                label="Locations Type"
                :error="locationdataerror.type"
                itemtext="name"
                itemvalue="value"
              />
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn small color="btnColor"
        class="white--text">
          <span @click="addLocationFun()">Add</span>
        </v-btn>
      </template>
    </lb-dailogboxnew>

     <lb-dailogboxnew
      v-model="addUsersAppPer"
      :heading="'Add ' + typeAppPer"
      width="600"
    >
      <template v-slot:body>
        <div>
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <lb-dropdown
                v-model="addAppPerLoa"
                :items="location_details"
                multiple
                label="Locations"
                itemtext="name"
                itemvalue="_id"
                disabled
              />
            </v-col>
            <v-col cols="12" class="my-0 py-0" v-if="typeAppPer === 'Preparer'">
              <lb-dropdown
                hidedetails
                v-model="addpreparerusers"
                :items="formdata.users"
                itemtext="name"
                itemvalue="_id"
                multiple
                :label="typeAppPer"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-0" v-if="typeAppPer === 'Approver'">
              <lb-dropdown
                hidedetails
                v-model="addapproverusers"
                :items="formdata.users"
                itemtext="name"
                itemvalue="_id"
                multiple
                :label="typeAppPer"
              />
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn small color="btnColor"
        class="white--text">
          <span @click="addUserLocationList(addAppPerLoa,addpreparerusers,addapproverusers)">Add</span>
        </v-btn>
      </template>
    </lb-dailogboxnew>
  </div>
</template>

<script>
import bulkChecklistUpload from "../../views/Components/BulkUploadChecklist.vue";
import DataTable from "../../../../views/common/DataTable.vue";
import XLSX from "xlsx-color";
export default {
  name: "financialclose_master_checklist",
  // props: ['props'],
  data: function () {
    return {
      right: "financialclose_master_checklist",
      // generateddescription: "",
      tableheader: [
        // { text: 'Sl No', value: 'slno' },
        { text: "Ref", value: "slno" },
        { text: "Description", value: "description", sortable: false },
        { text: "Assertions", value: "assertions" },
        { text: "Due days", value: "duedays" },
        { text: "Category", value: "category" },
        { text: "Sub Category", value: "subcategory" },
        { text: "Preparer(s)", value: "preparer", sortable: false },
        { text: "Approver(s)", value: "approver", sortable: false },
        // { text: 'Information Request', value: 'informationmanager', sortable: false },
        { text: "Tags", value: "tags", sortable: false },
        { text: "Action", value: "action", align: "center", sortable: false },
      ],
      possibleassertions: [
        "Existence",
        "Occurrence",
        "Rights and obligations",
        "Completeness",
        "Accuracy valuation and allocation",
        "Cut–off",
        "Classification",
        "Presentation",
        "Others",
      ],
      addeditdata: {},
      addeditdataerror: {},
      addeditdialog: false,
      addeditloading: false,
      loading: false,
      formdata: {},
      userlist: {},
      listdata: [],
      bulkactionuserdialog: false,
      multiselectdata: [],
      bulkactionuserselected: false,
      bulkactionuserusertype: "",
      bulkactionuserid: "",
      headers: [
        {
          value: "ref",
          text: "Ref",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "description",
          text: "Description",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "assertionscount",
          text: "Assertions",
          sortable: true,
          filterable: true,
          datatype: "number",
          lock: true,
          visible: false,
        },
        // store.state.LocationHeader
        {
          value: "location",
          text: this.$store.state.LocationHeader ? this.$store.state.LocationHeader : "Location",
          sortable: false,
          filterable: true,
          datatype: "string",
          lock: true,
          visible: false,
        },
        {
          value: "duedays",
          text: "Due Days",
          sortable: true,
          lock: true,
          visible: false,
        },
        {
          value: "category",
          text: "Category",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "subcategory",
          text: "Sub Category",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        // {
        //   value: "preparer",
        //   text: "Preparer(s)",
        // },
        // {
        //   value: "approver",
        //   text: "Approver(s)",
        // },
        {
          value: "tags",
          text: "Tags",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
      formatedData: [],
      possibleusers: [],
      bulkactionuserloading: false,
      selectaction: "add",
      changetouser: "",
      itemapprover: [
        { value: "associate", name: "Add" },
        { value: "remove", name: "Remove" },
        { value: "replace", name: "Replace" },
        { value: "move", name: "Change to Approver" },
      ],
      itempreparer: [
        { value: "associate", name: "Add" },
        { value: "remove", name: "Remove" },
        { value: "replace", name: "Replace" },
        { value: "move", name: "Change to Preparer" },
      ],
      activitylogconfig: {
        userassociate: {
          icon: "mdi-plus",
          color: "info",
          displaytext: "User Associated",
        },
        userreplaced: {
          icon: "mdi-account-convert",
          color: "info",
          displaytext: "User Replaced",
        },
        userremoved: {
          icon: "mdi-close",
          color: "error",
          displaytext: "User Removed",
        },
        usermoved: { icon: "mdi-sync", color: "", displaytext: "Updated" },
        // projectcreate: {
        //   icon: "mdi-plus",
        //   color: "info",
        //   displaytext: "Created",
        // },
        // projectopen: {
        //   icon: "mdi-lock-open-variant",
        //   color: "info",
        //   displaytext: "Opened",
        // },
        // projectclose: {
        //   icon: "mdi-lock",
        //   color: "success",
        //   displaytext: "Closed",
        // },
        tasklistupdated: {
          icon: "mdi-pencil",
          color: "fbcPrimary",
          displaytext: "Updated",
        },
        // tbadd: {
        //   icon: "mdi-table-plus",
        //   color: "error",
        //   displaytext: "Trial Balance Added",
        // },
        // bulkpbcadd: {
        //   icon: "mdi-file-question-outline",
        //   color: "",
        //   displaytext: "Information Request Added",
        // },
      },
      viewchecklistdialog: false,
      viewchecklistdata: {},
      userid: "",
      addnewLocation: false,
      addnewLocationloading: false,
      locationdialog: false,
      locationdialogloading: false,
      locations: "",
      location_details: [],
      users_details: [],
      adduserdialog: false,
      addApprover: [],
      addPrepares: [],
      addLocation: [],
      glcodes:[],
      locationUserList: [],
      tab: null,
      locationdata: {},
      locationdataerror: {},
      locationtypeItem: [],
      addLocationerr: {},
      subcategoryAcc:[],
      categoryAcc:[],
      glcodeAcc:[],
      customassertions: false,
      chartAcc:[],
      possibleGlCodes:[],
      locationBasedGl:[],
      addUsersAppPer:false,
      addAppPer:[],
      addpreparerusers:[],
      addapproverusers:[],
      typeAppPer:'',
      addAppPerLoa:''
    };
  },
  created() {
    this.init();
    this.processCOAData();
  },
  activated() {
    this.init();
  },
  components: {
    bulkChecklistUpload,
    DataTable,
  },
  methods: {
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.listdata.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1,
          ref: item.ref,
          category: item.category,
          subcategory: item.subcategory,
          duedays: item.duedays,
          assertionscount: item.assertions.length,
          assertions: item.assertions,
          location: this.locationfilter(item.location_details),
          locationlist: item.location,
          description: item.description,
          approver: this.nameReturn(item.approver),
          approverids: item.approver,
          preparer: this.nameReturn(item.preparer),
          preparerids: item.preparer,
          tags: item.tags,
          slno: item.slno,
          glcode:item.glcode,
          instruction: item.instruction,
          purpose: item.purpose,
          created_at: item.created_at,
          updated_at: item.updated_at,
          status: item.status,
          __click: this.viewChecklist,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;

      console.log("qwsedrtyu",this.$store.state.LocationHeader);
      
    },

    exportToCsv() {
      const headers = [
        'Reference',
        'Description',
        'Assertions',
        'Location',
        'DueDays',
        'Category',
        'Subcategory',
        'Tags',
        'Approver',
        'Preparer',
        'GLCode',
        'Instruction',
        'Purpose',
        'Created_at',
        'Updated_at'
      ];

      const data = this.formatedData.map(item => ({
        Reference :item.ref,
        Description :item.description,
        Assertions :  Array.isArray(item.assertions) ? item.assertions.join(', ') : item.assertions  ,
        Location: Array.isArray(item.location) ? item.location.join(', ') : item.location,
        DueDays : item.duedays,
        Category :item.category ,
        Subcategory : item.subcategory,
        Tags: Array.isArray(item.tags) ? item.tags.join(', ') : item.tags,
        Approver: Array.isArray(item.approver) ? item.approver.map(a => a.displayname).join(', ') : item.approver,
        Preparer: Array.isArray(item.preparer) ? item.preparer.map(a => a.displayname).join(', ') : item.preparer,
        GLCode: Array.isArray(item.glcode) ? item.glcode.join(', ') : item.glcode,
        Instruction :item.instruction,
        Purpose :item.purpose,
        Created_at :item.created_at,
        Updated_at :item.updated_at
      }));
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data, { header: headers });
      headers.forEach((header, index) => {
          const cell_address = XLSX.utils.encode_cell({ c: index, r: 0 });
          if (!ws[cell_address]) return;
          ws[cell_address].s = {
              font: {
                  bold: true,
                  color: { rgb: "FFFFFF" }               
                },
              fill: {
                  fgColor: { rgb: "black" } 
              }
          };
      });
      ws['!cols'] = headers.map((header) => {
        if (header === 'Description') {
            return { wch: 40 }; 
        }
        return { wch: 20 };
    });
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'Tasklist-master.xlsx');
    },

    init() {
      this.refreshData();
    },
    refreshData() {
      this.charts = [];
      this.chartdata = {};
      this.listdata = [];
      this.userid = this.$store.state.user.id;
      this.getData();
    },
    viewChecklist(item) {
      this.viewchecklistdata = { ...item };
      this.viewchecklistdialog = true;
      //console.log(item);
    },
    getData() {
      this.loading = true;
      this.$store.commit("setProjectName", "");
      return this.axios
        .post("/v2/financialclose/masters/checklist/get")
        .then((dt) => {
          this.listdata = [];
          if (dt.data.status === "success") {
            this.listdata = dt.data.data || [];
            const uniqueAssertions = new Set();
            this.listdata.forEach(item => {
              item.assertions.forEach(assertion => {
                uniqueAssertions.add(assertion);
              });
            });
            uniqueAssertions.add("Others");
            this.possibleassertions = Array.from(uniqueAssertions)
            this.formateData();
          } else throw new Error(dt.data.message);
        })
        .then(() => {
          return this.axios.post(
            "/v2/financialclose/masters/checklist/getformdata"
          );
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.formdata = dt.data.data[0] || {};
            this.location_details = this.formdata.location;
            this.users_details = this.formdata.users;
            this.userlist = {};
            let colors = this.$nova.getColors(
              (this.formdata.users || []).length,
              0.2
            );
            // console.log(colors);
            for (let i = 0; i < (this.formdata.users || []).length; i++) {
              const el = (this.formdata.users || [])[i];
              this.userlist[el._id] = el;
              this.userlist[el._id].color = colors[i];
              this.formateData();
            }
          } else throw new Error(dt.data.message);
        })
        .then(() => {
          this.axios
            .post("/v2/financialclose/location/formselects")
            .then((ele) => {
              let locationtypelist = ele.data.data;
              this.locationtypeItem = locationtypelist[0].type;
            });
            this.loading = false;
        })
        .then(() => {
          this.axios
            .post("/v2/financialclose/masters/chartofaccount/get")
            .then((ele) => {
              this.chartAcc = ele.data.data
              this.categoryAcc = this.chartAcc.map((x) => x.category);
              this.subcategoryAcc = this.chartAcc.map((y) => y.subcategory);
              this.glcodeAcc = this.chartAcc.map((z) => z.glcode)
              this.processCOAData();
            });
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    processCOAData(){
      this.location_details.forEach((location,index)=>{
        this.locationBasedGl[location._id] = []
        for( const glItem of this.chartAcc){
          for( const gllocation of (glItem.location || []) ){
            if (location._id === gllocation._id) {
             this.locationBasedGl[location._id].push(glItem)
            }
            if (index === this.location_details.length - 1) {
              this.setLocationBasedGL(this.locationBasedGl)
            }
          }
        }
         
      });
      //console.log(JSON.stringify(this.locationBasedGl), "FF")
        //  this.categoryAcc = ele.data.data;
        //  this.subcategoryAcc = ele.data.data;
    },
    setLocationBasedGL(data){
      this.locationBasedGl=data;
    },

    addEditForm(dt = {}) {
      this.locationUserList = dt.locationlist || [];
      this.addeditdata = { ...dt };
      this.addeditdataerror = {};
      this.addeditdialog = true;
    },
    addEditItem() {
      this.addeditloading = true;
      let type = "add";
      let url = "/v2/financialclose/masters/checklist/add";
      if (this.addeditdata._id) {
        type = "edit";
        url =
          "/v2/financialclose/masters/checklist/edit/" + this.addeditdata._id;
      }
      this.addeditdata.assertions = this.addeditdata.assertions && this.addeditdata.assertions.filter(item => item !== "Others");
      if(this.customassertions){
        const customassertion = this.addeditdata.customassertions.split(',').map(item => item.trim()) || {};
        this.possibleassertions = [...new Set([...customassertion, ...this.possibleassertions])];
        this.addeditdata.assertions = [...new Set([...customassertion, ...this.addeditdata.assertions])];
      }
      this.addeditdata.location = this.locationUserList;
      this.addeditdata.approver = [];
      this.addeditdata.approverids = [];
      this.addeditdata.preparer = [];
      this.addeditdata.preparerids = [];

      let hasError = false;
      this.addeditdata.location.forEach((item) => {
        if (item.location_id?.length > 0 && (item.preparer?.length===0 || !item.approver?.length===0)) {
          this.$store.commit("sbError", "preparer and approver cannot be empty");
          hasError = true;
        }
      });
      if (hasError) {
        this.addeditloading = false;
        return;
      }
      let ndt = { ...this.addeditdata };
      delete ndt._id;
      this.axios
        .post(url, { data: ndt })
        .then((dt) => {
          if (dt.data.status === "success") {
            if (type === "add")
              this.$store.commit("sbSuccess", "Checklist item added");
            else this.$store.commit("sbSuccess", "Checklist item updated");
            this.addeditdialog = false;
            this.locationUserList = [];
            this.refreshData();
          } else {
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0)
                this.addeditdataerror = dt.data.data[0].index0;
              if (this.addeditdataerror.tags) {
                throw new Error("Tags required");
              } else if (this.addeditdataerror.location) {
                throw new Error("Location required");
              }
            } else throw new Error(dt.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.addeditloading = false;
        });
    },
    enableDisableAccess(id, action) {
      this.loading = true;
      let url = "/v2/financialclose/masters/checklist/disable/";
      if (action === "enable") {
        url = "/v2/financialclose/masters/checklist/enable/";
      }
      this.axios
        .post(url + id, {})
        .then((dt) => {
          if (dt.data.status === "success") {
            if (action === "enable") {
              this.$store.commit("sbSuccess", "Item enabled");
              // this.listdata[k].status = true;
            } else {
              this.$store.commit("sbSuccess", "Item disabled");
              // this.listdata[k].status = false;
            }
            this.refreshData();
          } else throw new Error(dt.data.message || "Error performing action");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    bulkActionUserdilog(type, selectitems) {
      this.selectaction = "associate";
      this.bulkActionUser(type, selectitems);
    },
    actionselection(select, type, selectitems) {
      if (select === "remove") {
        this.bulkactionuserselected = true;
        this.bulkActionUser(false, selectitems);
      } else {
        this.bulkActionUser(type, selectitems);
      }
    },
    bulkActionUser(type, value) {
      this.multiselectdata = value;
      let selecteditems = value;
      let existingusers = new Set();
      let possibleids = [];

      if (type) {
        this.bulkactionuserselected = false;
        if (type === "preparer") this.bulkactionuserusertype = "Preparer";
        else if (type === "approver") this.bulkactionuserusertype = "Approver";
      }
      for (const i of this.listdata) {
        if (selecteditems.indexOf(i._id) > -1) {
          if (this.bulkactionuserusertype === "Preparer")
            (i.preparer || []).forEach(existingusers.add, existingusers);
          else if (this.bulkactionuserusertype === "Approver")
            (i.approver || []).forEach(existingusers.add, existingusers);
        }
      }

      if (!this.bulkactionuserselected) {
        for (const i of this.formdata.users || []) {
          this.possibleusers.push(i);
        }
      } else {
        possibleids = Array.from(existingusers);
        this.possibleusers = [];
        for (const i of this.formdata.users || []) {
          if (possibleids.indexOf(i._id) > -1) this.possibleusers.push(i);
        }
      }
      this.bulkactionuserdialog = true;
    },
    bulkManageUser(action, usertype, userid, selectitems, changeuserid) {
      let ndt = {};
      this.bulkactionuserloading = true;
      if (action === "replace") {
        ndt = { fromuserid: userid, touserid: changeuserid, type: action };
      } else {
        ndt = {
          userid: userid,
          usertype: (usertype || "").toLowerCase(),
          type: action,
        };
      }
      let fdt = { type: "checklist", _id: selectitems };
      let successflag = false;
      this.axios
        .post("/v2/financialclose/masters/checklist/managechecklistuser", {
          data: [ndt],
          filter: fdt,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.bulkactionuserdialog = false;
            let successMessage = "";
            if (action === "associate") successMessage = "User Added";
            else if (action === "remove") successMessage = "User Removed";
            else if (action === "move")
              successMessage = "Change to " + usertype;
            else if (action === "replace") successMessage = "User Replaced";
            this.$store.commit("sbSuccess", successMessage);
            this.changetouser = "";
            successflag = true;
          } else {
            throw new Error(dt.data.message || "Error changing status");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
        })
        .finally(() => {
          this.bulkactionuserloading = false;
          if (successflag) this.refreshData();
        });
    },
    bulkActionUserlocationdilog(type, value) {
      this.multiselectdata = value;
      this.locationdialog = true;
      this.addLocationerr = {};
      this.addApprover = [];
      this.addPrepares = [];
      this.addLocation = [];
    },
    nameReturn(ids) {
      let arrayTem = [];
      for (const id of ids) {
        arrayTem.push(this.userlist[id]);
      }
      return arrayTem;
    },
    getLocationName(locationId) {
      const location = this.location_details.find(
        (loc) => loc._id === locationId
      );
      return location ? location.name : "base";
    },
    getPreparesName(id) {
      const location = (this.formdata.users || []).find(
        (loc) => loc._id === id
      );
      return location ? location.name : "Unknown";
    },
    getApproversName(id) {
      const location = (this.formdata.users || []).find(
        (loc) => loc._id === id
      );
      return location ? location.name : "Unknown";
    },
    getGlCode(id){
      const glcode = (this.chartAcc || []).find(
        (gl) => gl._id === id
      );
      return glcode ? glcode.glcode : " ";
    },
addUserLocation(locationlist, preparer, approve) {
  if (!this.addeditdata._id && (locationlist.length === 0 || preparer.length === 0 || approve.length === 0)) {
    this.$store.commit("sbError", "locations, preparers, and approves must not be empty");
    return; // Do not proceed if any of them is empty
  }
  locationlist.forEach(locationId => {
    const existingLocationIndex = this.locationUserList.findIndex(userLocation => userLocation.location_id === locationId);

    if (existingLocationIndex !== -1) {
      // If the location exists, update the preparer and approver values
      this.locationUserList[existingLocationIndex].preparer = Array.from(new Set([...this.locationUserList[existingLocationIndex].preparer, ...preparer]));
      this.locationUserList[existingLocationIndex].approver = Array.from(new Set([...this.locationUserList[existingLocationIndex].approver, ...approve]));
    } else {
      // If the location doesn't exist, push a new entry
      const obj = {
        location_id: locationId,
        preparer: [...new Set(preparer)],
        approver: [...new Set(approve)],
      };
      this.locationUserList.push(obj);
    }
  });
  this.adduserdialog = false;
},
addUserLocationList(location, preparer, approver) {
  let locationList = [location];
  this.addUserLocation(locationList, preparer, approver);
  this.addUsersAppPer = false;  
},
removeUser(location, userId, userType) {
    let locationIndex = this.locationUserList.findIndex(x => x.location_id === location);
    if (locationIndex !== -1) {
      if (userType === 'preparer') {
        this.locationUserList[locationIndex].preparer = this.locationUserList[locationIndex].preparer.filter(preparer => preparer !== userId);
      } else if (userType === 'approver') {
        this.locationUserList[locationIndex].approver = this.locationUserList[locationIndex].approver.filter(approver => approver !== userId);
      }
    }
  },
// removePreparer(location,preparerId){
//  let locationIndex =  this.locationUserList.findIndex(x => x.location_id === location);
//  if (locationIndex !== -1) {
//       this.locationUserList[locationIndex].preparer = this.locationUserList[locationIndex].preparer.filter(approver => approver !== preparerId);
//     }
// },
// removeApprover(location,approverId){
//  let locationIndex =  this.locationUserList.findIndex(x => x.location_id === location);
//  if (locationIndex !== -1) {
//       this.locationUserList[locationIndex].approver = this.locationUserList[locationIndex].approver.filter(approver => approver !== approverId);
//     }
// },
    bulklocationChange(locations, approvers, preparers, ids) {
      this.addLocationerr = {};
      if (
        locations.length === 0 &&
        approvers.length === 0 &&
        preparers.length === 0
      ) {
        const arrays = ["locations", "approvers", "preparers"];

        arrays.forEach((array) => {
          this.addLocationerr[array] = "required";
        });
      } else {
        let data = {
          approver: approvers,
          preparer: preparers,
          location: locations,
        };
        this.axios
          .post("/v2/financialclose/masters/checklist/managelocation", {
            data: data,
            filter: { _id: ids },
          })
          .then((ele) => {
            if (ele.data.status === "success") {
              this.$store.commit(
                "sbSuccess",
                "location users add successfully"
              );
              this.locationdialog = false;
              this.getData();
            } else {
              this.addLocationerr = {};
              if (ele.data.data.length > 0) {
                if (ele.data.data[0].index0)
                  this.addLocationerr = ele.data.data[0].index0;
              } else
                throw new Error(ele.data.message || "Error adding location");
            }
          })
          .catch((err) => {
            this.$store.commit(
              "sbError",
              err.message || err || "Unknown error!"
            );
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    addLocationFun() {
      this.addnewLocationloading = true;
      this.axios
        .post("/v2/financialclose/location/add", { data: this.locationdata })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.addnewLocation = false;
            this.addnewLocationloading = false;
            this.$store.commit("sbSuccess", "location added successfully");
            this.getData();
          } else {
            if (ele.data.data.length > 0) {
              if (ele.data.data[0].index0)
                this.locationdataerror = ele.data.data[0].index0;
            } else throw new Error(ele.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          this.addnewLocationloading = false;
          console.log(err);
        })
        .finally(() => {
          this.addnewLocationloading = false;
        });
    },
    locationfilter(item = []){
       return item.map(header => header.name);
    },
     deleteLocation(id){
      this.locationUserList = this.locationUserList.filter((item) => item.location_id !== id)
    },
    usersAddlocation(location,type,preparerusers,papproverusers){
      this.addAppPerLoa = location;
      this.typeAppPer = type;
      this.addpreparerusers = preparerusers;
      this.addapproverusers = papproverusers;
      console.log(this.locationUserList);
      console.log(location,type);
      this.addUsersAppPer = true;
    },
  },
  watch: {
    "addeditdata.assertions": function () {
      if (this.addeditdata.assertions && this.addeditdata.assertions.indexOf("Others") !== -1) {
        this.customassertions = true;
      }
      else {
        this.customassertions = false;
      }
    },
    locationUserList: function() {
      this.possibleGlCodes = [];
      for (var location of this.locationUserList) {
        const glCodes = this.locationBasedGl[location.location_id]?.map(item => item.glcode);
        if (glCodes) {
          this.possibleGlCodes.push(...glCodes);
        }
      }
      this.possibleGlCodes = Array.from(new Set(this.possibleGlCodes));
    },
  },
};
</script>
